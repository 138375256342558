import React from "react";
import { WebUrl } from "../../util/Constant";
import NavigationButton from "./NavigationButton";
import { useSelector } from "react-redux";

const Header = (props) => {
  const {
    title,
    history,
    url,
    slot,
    state,
    hasBackButton = true,
    fromDashboard = false,
    ...rest
  } = props;
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );

  function handleUrl(url) {
    if (window.ReactNativeWebView) {
      if (fromDashboard) {
        return url;
      }
      return null;
    }

    if (url === WebUrl._URL_DASHBOARD) {
      if (activeTheme === 1) {
        url = WebUrl._URL_DASHBOARD_V2;
      }
      return url;
    } else {
      return url;
    }
  }

  return (
    <div
      className={
        "content-header home-header shared-header" +
        (activeTheme === 0 ? "" : " modern-header")
      }
    >
      <div className="top-header">
        <div>
          {hasBackButton && (
            <NavigationButton
              class="btn btn-icon"
              icon="fa fa-arrow-left"
              history={history}
              url={handleUrl(url)}
              state={state}
            />
          )}
          {title}
        </div>
        {slot && <div>{slot}</div>}
      </div>
    </div>
  );
};

export default Header;
