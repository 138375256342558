import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  SessionKey,
  Language,
  LanguageOption,
  LanguageSelectStyle,
} from "../../util/Constant";
import Input from "../../components/custom/Input";
import PhoneNumber from "../../components/custom/PhoneNumber";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, setBusy, setIdle } from "../../redux/AppAction";
import { createFormBody, createMultiPartFormBody } from "../../util/Util";
import { saveLanguageId } from "../../redux/LanguageAction";
import Select from "react-select";
import PinInput from "react-pin-input";
import { Alert, Modal, ModalHeader, ModalBody } from "reactstrap";
import classNames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import Icon from "@mdi/react";
import { mdiCogOutline, mdiLockOutline, mdiNumeric, mdiLogout } from "@mdi/js";
import { updateUserData, performLogout } from "../../redux/AuthAction";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import classicImage from "../../assets/img/classicSampleImage.PNG";
import modernImage from "../../assets/img/modernSampleImage.PNG";

/// <summary>
/// Author: -
/// </summary>
const Settings = (props) => {
  var _history = useHistory();
  var { t, i18n } = useTranslation();
  var _dispatch = useDispatch();
  var _userData = useSelector((state) => state.authState.userData);

  const [verificationModal, setVerificationModal] = useState(false);

  const {
    register: profileRegister,
    errors: profileErrors,
    handleSubmit: handleProfileSubmit,
    setValue: setProfileValue,
    getValues: getProfileValues,
    clearErrors: clearProfileErrors,
    watch: profileWatch,
  } = useForm();

  const {
    errors: resetPasswordErrors,
    handleSubmit: handleResetPasswordSubmit,
    watch: resetPasswordWatch,
    setValue: setResetPasswordValue,
    setError: setResetPasswordError,
    clearErrors: clearResetPasswordErrors,
    register: resetPasswordRegister,
  } = useForm();

  const {
    register: codeRegister,
    errors: codeErrors,
    getValues: getCodeValues,
    handleSubmit: handleCodeSubmit,
  } = useForm();

  const { handleSubmit: pinHandleSubmit } = useForm();

  const _COUNTDOWN_PERIOD = 120;
  const _SECONDS_IN_MS = 1000;
  const _ALERT_TIMEOUT = 5000;

  const [showAlert, setShowAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showThemeAlert, setShowThemeAlert] = useState(false);
  const [failMessage, setFailMessage] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [member, setMember] = useState();
  const [sentCode, setSentCode] = useState(false);
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [oldPinNumber, setOldPinNumber] = useState("");
  const [pinNumber, setPinNumber] = useState("");
  const [pinNumberConfirm, setPinNumberConfirm] = useState("");

  const [oldVipPinNumber, setOldVipPinNumber] = useState("");
  const [vipPinNumber, setVipPinNumber] = useState("");
  const [vipPinNumberConfirm, setVipPinNumberConfirm] = useState("");

  const [tempPinNumber, setTempPinNumber] = useState("");

  const [oldPinNumberFocused, setOldPinNumberFocused] = useState(0);
  const [pinNumberFocused, setPinNumberFocused] = useState(0);
  const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);

  const [oldVipPinNumberFocused, setOldVipPinNumberFocused] = useState(0);
  const [vipPinNumberFocused, setVipPinNumberFocused] = useState(0);
  const [vipPinNumberConfirmFocused, setVipPinNumberConfirmFocused] =
    useState(0);

  const [tempPinNumberFocused, setTempPinNumberFocused] = useState(0);

  const [checkPinExist, setCheckPinExist] = useState(true);
  const [showPinEntry, setShowPinEntry] = useState(false);
  const [showVipPinEntry, setShowVipPinEntry] = useState(false);
  const [allowPinClick, setAllowPinClick] = useState(true);
  const [selectedTheme, setSelectedTheme] = useState(0);
  const [isShareholder, setIsShareholder] = useState(false);

  const [currentPinRef, setCurrentPinRef] = useState(0);
  const pin_1 = useRef(null);
  const pin_2 = useRef(null);
  const pin_3 = useRef(null);
  const pin_vip_1 = useRef(null);
  const pin_vip_2 = useRef(null);
  const pin_vip_3 = useRef(null);
  const pin_temp = useRef(null);
  const pin_vip_temp = useRef(null);
  const keyboard = useRef();

  const _MENU_OPTION = {
    GeneralSettings: 1,
    ChangePassword: 2,
    ChangePin: 3,
    ChangeShareholderPin: 4,
  };
  const [activeMenu, setActiveMenu] = useState(_MENU_OPTION.GeneralSettings);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();

    return () => {
      setIsContentLoaded(false);
    };
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    let params = {};
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CHECK_MEMBER_PIN_NUMBER,
      createFormBody(params)
    );
    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      setCheckPinExist(false);
    }
    responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMember(responseJson[ApiKey._API_DATA_KEY]);
      setIsShareholder(responseJson[ApiKey._API_DATA_KEY]["isShareHolder"]);
      setSelectedTheme(responseJson[ApiKey._API_DATA_KEY]["selectedTheme"]);
      setIsContentLoaded(true);
      setProfileValue(
        "username",
        responseJson[ApiKey._API_DATA_KEY]["username"]
      );
      setProfileValue(
        "phoneNumber",
        "+" + responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]
      );
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }

  /// <summary>
  /// Author: -
  /// </summary>
  const updateProfile = async (data) => {
    data["id"] = _userData.userId;

    data["phoneNumber"] = formattedPhoneNumber;

    data["isMemberOperate"] = true;

    var responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_OR_UPDATE_MEMBER,
      createMultiPartFormBody(data)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      clearProfileErrors();
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  };

  /// <summary>
  /// Author: -
  /// </summary>
  const resetPassword = async (data) => {
    data["memberId"] = _userData.userId;

    var responseJson = await ApiEngine.post(
      ApiUrl._API_MEMBER_RESET_PASSWORD,
      createMultiPartFormBody(data)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setResetPasswordValue("currentPassword", "");
      setResetPasswordValue("password", "");
      setResetPasswordValue("confirmPassword", "");
      clearResetPasswordErrors();
    }

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
  };

  /// <summary>
  /// Author: -
  /// </summary>
  const onChangeLanguage = async (selectedLanguage) => {
    _dispatch(setBusy());
    i18n.changeLanguage(selectedLanguage);

    _dispatch(saveLanguageId(selectedLanguage));

    let data = {};
    data["id"] = member["id"];
    data["selectedTheme"] = _userData.selectedTheme;
    data["languageSetting"] = selectedLanguage;
    data["isMemberOperate"] = true;

    // Silently update user's language settings
    await ApiEngine.post(
      ApiUrl._API_CREATE_OR_UPDATE_MEMBER,
      createMultiPartFormBody(data)
    );
    _dispatch(setIdle());

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "language",
          language: selectedLanguage,
        })
      );
    }
  };

  /// <summary>
  /// Author: Wong
  /// </summary>
  const sendVerificationCode = async () => {
    _dispatch(setBusy());
    await ApiEngine.post(
      ApiUrl._API_SEND_VERIFICATION_CODE,
      createMultiPartFormBody({ phoneNumber: formattedPhoneNumber })
    ).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        setSentCode(true);
        setCountdownPeriod(_COUNTDOWN_PERIOD);
        setVerificationModal(true);
      }
    });
    _dispatch(setIdle());
  };

  const changePin = async () => {
    let params = {
      oldPinExist: checkPinExist,
      oldPinNumber: oldPinNumber,
      pinNumber: pinNumber,
      confirmPinNumber: pinNumberConfirm,
    };

    var responseJson = await ApiEngine.post(
      ApiUrl._API_SET_MEMBER_PIN_NUMBER,
      createFormBody(params)
    );

    pin_1["current"].clear();
    pin_2["current"].clear();
    pin_3["current"].clear();
    setOldPinNumber("");
    setPinNumber("");
    setPinNumberConfirm("");
    setTempPinNumber("");
    setOldPinNumberFocused(0);
    setPinNumberFocused(0);
    setPinNumberConfirmFocused(0);

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
  };

  const changeShareholderPin = async () => {
    let params = {
      oldPinNumber: oldVipPinNumber,
      pinNumber: vipPinNumber,
      confirmPinNumber: vipPinNumberConfirm,
    };

    var responseJson = await ApiEngine.post(
      ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
      createFormBody(params)
    );

    pin_vip_1["current"].clear();
    pin_vip_2["current"].clear();
    pin_vip_3["current"].clear();
    setOldVipPinNumber("");
    setVipPinNumber("");
    setVipPinNumberConfirm("");
    setTempPinNumber("");
    setOldVipPinNumberFocused(0);
    setVipPinNumberFocused(0);
    setVipPinNumberConfirmFocused(0);

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
  };

  /// <summary>
  /// Author: Wong
  /// </summary>
  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    }
  }, [countdownPeriod]);

  /// <summary>
  /// Author: Wong
  /// </summary>
  const verifyPhoneNumber = async () => {
    await ApiEngine.post(
      ApiUrl._API_VERIFY_PHONE_NUMBER,
      createMultiPartFormBody({
        verificationCode: getCodeValues("verificationCode"),
      })
    ).then((response) => {
      _dispatch(
        showResponseMessage(
          response[ApiKey._API_SUCCESS_KEY],
          response[ApiKey._API_MESSAGE_KEY]
        )
      );
      if (response[ApiKey._API_SUCCESS_KEY]) {
        setVerificationModal(false);
        init();
      }
    });
  };

  function onKeyPress(key) {
    setAllowPinClick(false);
    // 1st: have to check currentPinRef
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = tempPinNumber;
    if (key !== "{bksp}") {
      if (currentPinRef === 1) {
        currentIndex = tempPinNumberFocused;
        pin_temp["current"]["elements"][currentIndex].focus();
        pin_1["current"]["elements"][currentIndex].state.value = key;
        pin_temp["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } else {
          setOldPinNumber(pinStr);
          setTempPinNumber("");
          pin_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowPinEntry(false);
          setTimeout(function () {
            console.log("test");
            setAllowPinClick(true);
          }, 1000);
        }
      } else if (currentPinRef === 2) {
        currentIndex = tempPinNumberFocused;
        pin_temp["current"]["elements"][currentIndex].focus();
        pin_2["current"]["elements"][currentIndex].state.value = key;
        pin_temp["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } else {
          setPinNumber(pinStr);
          setTempPinNumber("");
          pin_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowPinEntry(false);
          setTimeout(function () {
            console.log("test");
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        currentIndex = tempPinNumberFocused;
        pin_temp["current"]["elements"][currentIndex].focus();
        pin_3["current"]["elements"][currentIndex].state.value = key;
        pin_temp["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } else {
          setPinNumberConfirm(pinStr);
          setTempPinNumber("");
          pin_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowPinEntry(false);
          setTimeout(function () {
            console.log("test");
            setAllowPinClick(true);
          }, 1000);
        }
      }
      //if (pinNumber === "") {
      //    currentIndex = pinNumberFocused;
      //    elePin['current']['elements'][currentIndex].state.value = key;
      //    if (currentIndex !== 3) {
      //        currentIndex++;
      //        setPinNumberFocused(currentIndex);
      //        elePin['current']['elements'][currentIndex].focus();
      //    }
      //    else {
      //        elePin['current']['values'].map((pin, index) => { //convert elePin to string
      //            pinStr += key;
      //        })
      //        setPinNumber(pinStr);
      //    }
      //}
      //else {
      //    currentIndex = pinNumberConfirmFocused;
      //    eleConfirm['current']['elements'][currentIndex].state.value = key;
      //    currentIndex++;
      //    setPinNumberConfirmFocused(currentIndex);
      //    if (currentIndex <= 3) {
      //        eleConfirm['current']['elements'][currentIndex].focus();
      //    }
      //    else {
      //        eleConfirm['current']['values'].map((pin, index) => { //convert eleConfirm to string, and submitPin
      //            pinConfirmStr += key;
      //        })
      //        setPinNumberConfirm(pinConfirmStr);
      //        submitPin(pinNumber, pinConfirmStr);
      //    }
      //}
    } else {
      pin_1["current"].clear();
      pin_2["current"].clear();
      pin_3["current"].clear();
      pin_temp["current"].clear();
      setOldPinNumber("");
      setPinNumber("");
      setPinNumberConfirm("");
      setTempPinNumber("");
      setOldPinNumberFocused(0);
      setPinNumberFocused(0);
      setPinNumberConfirmFocused(0);
      setTempPinNumberFocused(0);
    }
  }

  function onVipKeyPress(key) {
    setAllowPinClick(false);
    // 1st: have to check currentPinRef
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = tempPinNumber;
    if (key !== "{bksp}") {
      if (currentPinRef === 1) {
        currentIndex = tempPinNumberFocused;
        pin_vip_temp["current"]["elements"][currentIndex].focus();
        pin_vip_1["current"]["elements"][currentIndex].state.value = key;
        pin_vip_temp["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } else {
          setOldVipPinNumber(pinStr);
          setTempPinNumber("");
          pin_vip_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowVipPinEntry(false);
          setTimeout(function () {
            console.log("test");
            setAllowPinClick(true);
          }, 1000);
        }
      } else if (currentPinRef === 2) {
        currentIndex = tempPinNumberFocused;
        pin_vip_temp["current"]["elements"][currentIndex].focus();
        pin_vip_2["current"]["elements"][currentIndex].state.value = key;
        pin_vip_temp["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } else {
          setVipPinNumber(pinStr);
          setTempPinNumber("");
          pin_vip_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowVipPinEntry(false);
          setTimeout(function () {
            console.log("test");
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        currentIndex = tempPinNumberFocused;
        pin_vip_temp["current"]["elements"][currentIndex].focus();
        pin_vip_3["current"]["elements"][currentIndex].state.value = key;
        pin_vip_temp["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } else {
          setVipPinNumberConfirm(pinStr);
          setTempPinNumber("");
          pin_vip_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowVipPinEntry(false);
          setTimeout(function () {
            console.log("test");
            setAllowPinClick(true);
          }, 1000);
        }
      }
    } else {
      pin_vip_1["current"].clear();
      pin_vip_2["current"].clear();
      pin_vip_3["current"].clear();
      pin_vip_temp["current"].clear();
      setOldVipPinNumber("");
      setVipPinNumber("");
      setVipPinNumberConfirm("");
      setTempPinNumber("");
      setOldVipPinNumberFocused(0);
      setVipPinNumberFocused(0);
      setVipPinNumberConfirmFocused(0);
      setTempPinNumberFocused(0);
    }
  }

  async function handleRadioBtn(themeId) {
    let params = {
      selectedTheme: themeId,
    };
    var responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_THEME,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(setBusy());
      setSelectedTheme(themeId);
      await _dispatch(updateUserData());
      setShowThemeAlert(true);
      _dispatch(setIdle());
      setTimeout(() => setShowThemeAlert(false), 3000);
    }

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "change-theme",
          themeId,
        })
      );
    }
  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_DASHBOARD}
        title={t("SETTINGS")}
        slot={
          <Select
            className="lang-selector"
            menuPlacement="auto"
            menuPosition="fixed"
            options={LanguageOption.map((x) => {
              return { label: x["prefix"], value: x["value"] };
            })}
            isSearchable={false}
            styles={LanguageSelectStyle}
            placeholder={
              LanguageOption.filter(
                (option) =>
                  option.value == localStorage.getItem(SessionKey._LANGUAGE)
              )[0] !== undefined
                ? LanguageOption.filter(
                    (option) =>
                      option.value == localStorage.getItem(SessionKey._LANGUAGE)
                  )[0].prefix
                : "EN"
            }
            value={
              LanguageOption.filter(
                (option) =>
                  option.value == localStorage.getItem(SessionKey._LANGUAGE)
              )[0] !== undefined
                ? LanguageOption.filter(
                    (option) =>
                      option.value == localStorage.getItem(SessionKey._LANGUAGE)
                  )[0].prefix
                : Language._ENGLISH
            }
            onChange={(e) => {
              onChangeLanguage(e.value);
            }}
          />
        }
      />
      <div
        className={
          "container mt-3 settings-page" +
          (selectedTheme === 0 ? "" : " modern")
        }
        style={{ paddingBottom: "30px" }}
      >
        {isContentLoaded && (
          <>
            <form
              className="margin-bottom-0"
              onSubmit={handleProfileSubmit(updateProfile)}
            >
              <div>
                <div
                  className={classNames(
                    "form-group d-flex justify-content-between align-items-center position-relative",
                    { "not-touchable": member.isPhoneVerify }
                  )}
                >
                  <PhoneNumber
                    className="p-0"
                    mainContainerStyle={{ marginBottom: "0", flex: 1 }}
                    register={profileRegister}
                    name={"phoneNumber"}
                    value={profileWatch("phoneNumber")}
                    errors={profileErrors}
                    onChange={(value, formattedValue) => {
                      setProfileValue("phoneNumber", value);
                      setFormattedPhoneNumber(formattedValue);
                    }}
                  />
                  {!member.isPhoneVerify ? (
                    <button
                      type="submit"
                      className="btn btn-brand-gradient"
                      style={{ marginLeft: "1rem" }}
                      onClick={() => {
                        if (!sentCode || countdownPeriod === 0) {
                          sendVerificationCode();
                        }
                      }}
                      disabled={sentCode && countdownPeriod !== 0}
                    >
                      {t("SEND_VERIFICATION_CODE")}
                    </button>
                  ) : (
                    <div className="badge bg-gradient-green badge-verified">
                      <i className="fa fa-check"></i> {t("VERIFIED")}
                    </div>
                  )}
                </div>
                <Input
                  placeholder={t("ENTER_USERNAME")}
                  name={"username"}
                  autoComplete={"off"}
                  label={t("USERNAME")}
                  ref={profileRegister({
                    required: "REQUIRED_USERNAME",
                  })}
                  readOnly={true}
                  defaultValue={profileWatch("username") || ""}
                  onInput={(e) => {
                    setProfileValue("username", e.target.value);
                  }}
                  errors={profileErrors}
                />
              </div>
            </form>
            <Nav className="transaction-nav brand-nav">
              <NavItem
                active={activeMenu == _MENU_OPTION.GeneralSettings}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenu(_MENU_OPTION.GeneralSettings);
                }}
              >
                <NavLink>
                  <div className="setting-options-icon-bg">
                    <Icon path={mdiCogOutline} size={1} color="#002C68" />
                  </div>
                  <div className="settings-options-text">
                    {t("GENERAL_SETTINGS")}
                  </div>
                </NavLink>
              </NavItem>
              <NavItem
                active={activeMenu == _MENU_OPTION.ChangePassword}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenu(_MENU_OPTION.ChangePassword);
                }}
              >
                <NavLink>
                  <div className="setting-options-icon-bg">
                    <Icon path={mdiLockOutline} size={1} color="#002C68" />
                  </div>
                  <div className="settings-options-text">
                    {t("CHANGE_PASSWORD")}
                  </div>
                </NavLink>
              </NavItem>
              <NavItem
                active={activeMenu == _MENU_OPTION.ChangePin}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenu(_MENU_OPTION.ChangePin);
                }}
              >
                <NavLink>
                  <div className="setting-options-icon-bg">
                    <Icon path={mdiNumeric} size={1} color="#002C68" />
                  </div>
                  <div className="settings-options-text">{t("CHANGE_PIN")}</div>
                </NavLink>
              </NavItem>
              {isShareholder && (
                <NavItem
                  active={activeMenu == _MENU_OPTION.ChangeShareholderPin}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveMenu(_MENU_OPTION.ChangeShareholderPin);
                  }}
                >
                  <NavLink>
                    <div className="setting-options-icon-bg">
                      <Icon path={mdiNumeric} size={1} color="#002C68" />
                    </div>
                    <div className="settings-options-text">
                      {t("CHANGE_SHAREHOLDER_PIN")}
                    </div>
                  </NavLink>
                </NavItem>
              )}
              <NavItem
                onClick={() => {
                  _dispatch(performLogout());
                }}
              >
                <NavLink>
                  <div className="setting-options-icon-bg">
                    <Icon path={mdiLogout} size={1} color="#002C68" />
                  </div>
                  <div className="settings-options-text">{t("LOGOUT")}</div>
                </NavLink>
              </NavItem>
            </Nav>
            {activeMenu == _MENU_OPTION.GeneralSettings && (
              <>
                <label
                  htmlFor="theme"
                  style={{
                    color: _userData.selectedTheme === 0 ? "#002e6c" : "white",
                  }}
                >
                  {t("THEME")}
                </label>
                <div
                  id="theme"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "30px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <input
                        id="classic"
                        type="radio"
                        name="settings-theme"
                        className="settings-radio-btn"
                        defaultChecked={selectedTheme === 0}
                        onChange={() => handleRadioBtn(0)}
                      />
                      <label
                        className="settings-radio-btn-label"
                        htmlFor="classic"
                      >
                        {t("CLASSIC")}
                      </label>
                    </div>
                    <div
                      className="image-sample class-image-sample"
                      style={{
                        display: "block",
                        backgroundImage: `url(${classicImage})`,
                      }}
                    ></div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <input
                        id="modern"
                        type="radio"
                        name="settings-theme"
                        className="settings-radio-btn"
                        defaultChecked={selectedTheme === 1}
                        onChange={() => handleRadioBtn(1)}
                      />
                      <label
                        className="settings-radio-btn-label"
                        htmlFor="modern"
                      >
                        {t("MODERN")}
                      </label>
                    </div>
                    <div
                      className="image-sample modern-image-sample"
                      style={{
                        display: "block",
                        backgroundImage: `url(${modernImage})`,
                      }}
                    ></div>
                  </div>
                </div>
              </>
            )}
            {activeMenu == _MENU_OPTION.ChangePassword && (
              <div className="p-20 setting-change-password">
                <form
                  className="margin-bottom-0"
                  onSubmit={handleResetPasswordSubmit(resetPassword)}
                >
                  <Input
                    placeholder={t("ENTER_OLD_PASSWORD")}
                    name={"currentPassword"}
                    autoComplete={"off"}
                    label={t("OLD_PASSWORD")}
                    type="password"
                    ref={resetPasswordRegister({
                      required: "REQUIRED_OLD_PASSWORD",
                    })}
                    value={resetPasswordWatch("currentPassword") || ""}
                    onInput={(e) => {
                      setResetPasswordValue("currentPassword", e.target.value);
                    }}
                    errors={resetPasswordErrors}
                  />
                  <Input
                    placeholder={t("ENTER_NEW_PASSWORD")}
                    name={"password"}
                    autoComplete={"off"}
                    label={t("NEW_PASSWORD")}
                    type="password"
                    ref={resetPasswordRegister({
                      required: "REQUIRED_NEW_PASSWORD",
                    })}
                    value={resetPasswordWatch("password") || ""}
                    onInput={(e) => {
                      if (
                        e.target.value === resetPasswordWatch("currentPassword")
                      ) {
                        setResetPasswordError("password", {
                          message: "NEW_PASSWORD_SAME_AS_OLD_PASSWORD",
                        });
                      } else {
                        clearResetPasswordErrors("password");
                      }
                      setResetPasswordValue("password", e.target.value);
                    }}
                    errors={resetPasswordErrors}
                  />
                  <Input
                    placeholder={t("ENTER_CONFIRM_NEW_PASSWORD")}
                    name={"confirmPassword"}
                    autoComplete={"off"}
                    label={t("CONFIRM_NEW_PASSWORD")}
                    type="password"
                    ref={resetPasswordRegister({
                      required: "REQUIRED_CONFIRM_NEW_PASSWORD",
                      validate: (value) =>
                        value == resetPasswordWatch("password") ||
                        "PASSWORD_MISMATCH",
                    })}
                    value={resetPasswordWatch("confirmPassword") || ""}
                    onInput={(e) => {
                      setResetPasswordValue("confirmPassword", e.target.value);
                    }}
                    errors={resetPasswordErrors}
                  />
                  <button
                    type="submit"
                    className="btn btn-block btn-brand-gradient"
                  >
                    {t("SAVE")}
                  </button>
                </form>
              </div>
            )}
            {activeMenu == _MENU_OPTION.ChangePin && (
              <div className="p-20 setting-change-pin">
                <form onSubmit={pinHandleSubmit(changePin)}>
                  {checkPinExist && (
                    <div
                      onClick={() => {
                        if (!allowPinClick) return false;
                        setCurrentPinRef(1);
                        setShowPinEntry(true);
                      }}
                    >
                      <label className="change-pin-label">
                        <b>{t("OLD_PIN")}</b>
                      </label>
                      <PinInput
                        ref={pin_1}
                        length={4}
                        style={{ paddingLeft: "10px", marginBottom: "20px" }}
                        initialValue="----"
                        secret
                        type="numeric"
                        disabled={true}
                        onChange={(value, index) => {
                          setOldPinNumberFocused(index);
                        }}
                        inputMode="numeric"
                        inputStyle={{ border: "0", margin: "0px 10px" }}
                        regexCriteria={/^[ 0-9_@./#&+-]*$/}
                      />
                    </div>
                  )}
                  <div
                    onClick={() => {
                      if (!allowPinClick) return false;
                      setCurrentPinRef(2);
                      setShowPinEntry(true);
                    }}
                  >
                    <label className="change-pin-label">
                      <b>{checkPinExist ? t("NEW_PIN") : t("PIN")}</b>
                    </label>
                    <PinInput
                      ref={pin_2}
                      length={4}
                      style={{ paddingLeft: "10px", marginBottom: "20px" }}
                      initialValue="----"
                      secret
                      type="numeric"
                      disabled={true}
                      onChange={(value, index) => {
                        setPinNumberFocused(index);
                      }}
                      inputMode="numeric"
                      inputStyle={{ border: "0", margin: "0px 10px" }}
                      regexCriteria={/^[ 0-9_@./#&+-]*$/}
                    />
                  </div>
                  <div
                    onClick={() => {
                      if (!allowPinClick) return false;
                      setCurrentPinRef(3);
                      setShowPinEntry(true);
                    }}
                  >
                    <label className="change-pin-label">
                      <b>{t("CONFIRM_PIN")}</b>
                    </label>
                    <PinInput
                      ref={pin_3}
                      style={{ marginBottom: "45px", paddingLeft: "10px" }}
                      length={4}
                      initialValue="----"
                      secret
                      type="numeric"
                      disabled={true}
                      onChange={(value, index) => {
                        setPinNumberConfirmFocused(index);
                      }}
                      inputMode="numeric"
                      inputStyle={{ border: "0", margin: "0px 10px" }}
                      regexCriteria={/^[ 0-9_@./#&+-]*$/}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-block btn-brand-gradient"
                  >
                    {t("SAVE")}
                  </button>
                </form>
              </div>
            )}
            {activeMenu == _MENU_OPTION.ChangeShareholderPin && (
              <div className="p-20 setting-change-pin">
                <form onSubmit={pinHandleSubmit(changeShareholderPin)}>
                  <div
                    onClick={() => {
                      if (!allowPinClick) return false;
                      setCurrentPinRef(1);
                      setShowVipPinEntry(true);
                    }}
                  >
                    <label className="change-pin-label">
                      <b>{t("OLD_SHAREHOLDER_PIN")}</b>
                    </label>
                    <PinInput
                      ref={pin_vip_1}
                      length={4}
                      style={{ paddingLeft: "10px", marginBottom: "20px" }}
                      initialValue="----"
                      secret
                      type="numeric"
                      disabled={true}
                      onChange={(value, index) => {
                        setOldVipPinNumberFocused(index);
                      }}
                      inputMode="numeric"
                      inputStyle={{ border: "0", margin: "0px 10px" }}
                      regexCriteria={/^[ 0-9_@./#&+-]*$/}
                    />
                  </div>
                  <div
                    onClick={() => {
                      if (!allowPinClick) return false;
                      setCurrentPinRef(2);
                      setShowVipPinEntry(true);
                    }}
                  >
                    <label className="change-pin-label">
                      <b>{t("NEW_SHAREHOLDER_PIN")}</b>
                    </label>
                    <PinInput
                      ref={pin_vip_2}
                      length={4}
                      style={{ paddingLeft: "10px", marginBottom: "20px" }}
                      initialValue="----"
                      secret
                      type="numeric"
                      disabled={true}
                      onChange={(value, index) => {
                        setVipPinNumberFocused(index);
                      }}
                      inputMode="numeric"
                      inputStyle={{ border: "0", margin: "0px 10px" }}
                      regexCriteria={/^[ 0-9_@./#&+-]*$/}
                    />
                  </div>
                  <div
                    onClick={() => {
                      if (!allowPinClick) return false;
                      setCurrentPinRef(3);
                      setShowVipPinEntry(true);
                    }}
                  >
                    <label className="change-pin-label">
                      <b>{t("CONFIRM_SHAREHOLDER_PIN")}</b>
                    </label>
                    <PinInput
                      ref={pin_vip_3}
                      style={{ marginBottom: "45px", paddingLeft: "10px" }}
                      length={4}
                      initialValue="----"
                      secret
                      type="numeric"
                      disabled={true}
                      onChange={(value, index) => {
                        setVipPinNumberConfirmFocused(index);
                      }}
                      inputMode="numeric"
                      inputStyle={{ border: "0", margin: "0px 10px" }}
                      regexCriteria={/^[ 0-9_@./#&+-]*$/}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-block btn-brand-gradient"
                  >
                    {t("SAVE")}
                  </button>
                </form>
              </div>
            )}{" "}
          </>
        )}
        <Modal
          fade={false}
          contentClassName="modal-brand modal-bottom"
          isOpen={verificationModal}
          toggle={() => {
            setVerificationModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setVerificationModal(false);
            }}
          >
            {t("PHONE_VERIFICATION")}
          </ModalHeader>
          <ModalBody style={{ minHeight: "unset" }}>
            <form onSubmit={handleCodeSubmit(verifyPhoneNumber)}>
              {/*<div className="form-group">
                <PhoneNumber
                  mainContainerClassName="not-touchable form-group"
                  className="p-0"
                  value={getProfileValues("phoneNumber")}
                />
                <div className="text-center">
                  {!sentCode || countdownPeriod == 0 ? (
                    <button
                      type="button"
                      onClick={() => sendVerificationCode()}
                      className="btn btn-block btn-brand-gradient"
                    >
                      {t("SEND_VERIFICATION_CODE")}
                    </button>
                  ) : (
                    <p>
                      {t("VERIFICATION_CODE_SENT")} {t("RESEND_IN")}{" "}
                      {countdownPeriod}s
                    </p>
                  )}
                </div>
              </div>*/}
              {sentCode && countdownPeriod !== 0 && (
                <>
                  <div>
                    <Input
                      name={"verificationCode"}
                      placeholder={t("ENTER_VERIFICATION_CODE")}
                      type="tel"
                      ref={codeRegister({
                        required: "FIELD_REQUIRED",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "INVALID_VERIFICATION_CODE",
                        },
                      })}
                      errors={codeErrors}
                    />
                  </div>
                  <p>
                    {t("VERIFICATION_CODE_SENT")} {t("RESEND_IN")}{" "}
                    {countdownPeriod}s
                  </p>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-block btn-brand-gradient"
                    >
                      {t("SUBMIT")}
                    </button>
                  </div>
                </>
              )}
            </form>
          </ModalBody>
        </Modal>
        <Modal
          fade={false}
          contentClassName="modal-brand modal-bottom"
          isOpen={showPinEntry}
          toggle={() => {
            setAllowPinClick(true);
            setShowPinEntry(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setAllowPinClick(true);
              setShowPinEntry(false);
            }}
          ></ModalHeader>
          <div className="changePin-modal-header">
            {currentPinRef === 1
              ? t("OLD_PIN")
              : currentPinRef === 2
              ? t("NEW_PIN")
              : t("CONFIRM_PIN")}
          </div>
          <ModalBody style={{ minHeight: "unset" }}>
            <div style={{ textAlign: "center", paddingBottom: "30px" }}>
              <PinInput
                ref={pin_temp}
                length={4}
                initialValue="----"
                secret
                type="numeric"
                disabled={showPinEntry}
                onChange={(value, index) => {
                  setTempPinNumberFocused(index);
                }}
                inputMode="numeric"
                inputStyle={{ border: "0", margin: "0px 10px" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
            </div>
            <Keyboard
              layout={{
                default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
              }}
              display={{
                "{bksp}": "<i class='fas fa-backspace'></i>",
              }}
              theme={`keyboard keyboard-numpad`}
              keyboardRef={(r) => (keyboard.current = r)}
              onKeyPress={onKeyPress}
              disableButtonHold
              disableCaretPositioning
            />
          </ModalBody>
        </Modal>
        <Modal
          fade={false}
          contentClassName="modal-brand modal-bottom"
          isOpen={showVipPinEntry}
          toggle={() => {
            setAllowPinClick(true);
            setShowVipPinEntry(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setAllowPinClick(true);
              setShowVipPinEntry(false);
            }}
          ></ModalHeader>
          <div className="changePin-modal-header">
            {currentPinRef === 1
              ? t("OLD_SHAREHOLDER_PIN")
              : currentPinRef === 2
              ? t("NEW_SHAREHOLDER_PIN")
              : t("CONFIRM_SHAREHOLDER_PIN")}
          </div>
          <ModalBody style={{ minHeight: "unset" }}>
            <div style={{ textAlign: "center", paddingBottom: "30px" }}>
              <PinInput
                ref={pin_vip_temp}
                length={4}
                initialValue="----"
                secret
                type="numeric"
                disabled={showPinEntry}
                onChange={(value, index) => {
                  setTempPinNumberFocused(index);
                }}
                inputMode="numeric"
                inputStyle={{ border: "0", margin: "0px 10px" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
            </div>
            <Keyboard
              layout={{
                default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
              }}
              display={{
                "{bksp}": "<i class='fas fa-backspace'></i>",
              }}
              theme={`keyboard keyboard-numpad`}
              keyboardRef={(r) => (keyboard.current = r)}
              onKeyPress={onVipKeyPress}
              preventMouseDownDefault
              disableButtonHold
              disableCaretPositioning
            />
          </ModalBody>
        </Modal>
        {showAlert && (
          <div
            className="content content-full-width"
            style={{
              position: "absolute",
              top: "10px",
              right: "0",
              width: "50%",
            }}
          >
            <div className="container">
              <Alert color="green">{t("PIN_CHANGED")}</Alert>
            </div>
          </div>
        )}
        {showFailAlert && (
          <div
            className="content content-full-width"
            style={{
              position: "absolute",
              top: "10px",
              right: "0",
              width: "50%",
            }}
          >
            <div className="container">
              <Alert color="red">{failMessage}</Alert>
            </div>
          </div>
        )}
        {showThemeAlert && (
          <div
            className="content content-full-width"
            style={{
              position: "absolute",
              top: "10px",
              right: "0",
              width: "50%",
            }}
          >
            <div className="container">
              <Alert color="green">{t("THEME_UPDATED")}</Alert>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Settings;
