import React, { useEffect, useState, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { performLogin } from "../redux/AuthAction.js";
import {
  WebUrl,
  ApiUrl,
  ApiKey,
  LanguageOption,
  SessionKey,
  Language,
  LanguageSelectStyle,
} from "../util/Constant";
import { useTranslation } from "react-i18next";
import Input from "../components/custom/Input";
import Checkbox from "../components/custom/Checkbox";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import PhoneNumber from "../components/custom/PhoneNumber.js";
import ApiEngine from "../util/ApiEngine.js";
import { createFormBody, createMultiPartFormBody } from "../util/Util.js";
import { setBusy, setIdle, showResponseMessage } from "../redux/AppAction.js";
import { saveLanguageId } from "../redux/LanguageAction";
import Select from "react-select";
import SecureLS from "secure-ls";
import ReCAPTCHA from "react-google-recaptcha";

/// <summary>
/// Author: -
/// </summary>
const Login = (props) => {
  var { t, i18n } = useTranslation();
  var _dispatch = useDispatch();
  var { isLoggedIn } = useSelector((state) => state.authState);
  var userData = useSelector((state) => state.authState.userData);
  var _history = useHistory();
  var ls = new SecureLS();
  const {
    register: loginRegister,
    handleSubmit: loginHandleSubmit,
    errors: loginErrors,
  } = useForm();
  const {
    register: forgotPasswordRegister,
    handleSubmit: forgotPasswordHandleSubmit,
    errors: forgotPasswordErrors,
    watch: forgotPasswordWatch,
    setValue: forgotPasswordSetValue,
    unregister: forgotPasswordUnregister,
  } = useForm();
  const { handleSubmit: recaptchaSubmit } = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [loginFailCount, setLoginFailCount] = useState(0);
  const [loginAttemptUsername, setLoginAttemptUsername] = useState("");
  const recaptcharef = useRef();

  const TEST_SITE_KEY = "6LffRtsbAAAAAODSwUqbIwV2mKf5HFmro6_OD4Yy"; //6LffRtsbAAAAAODSwUqbIwV2mKf5HFmro6_OD4Yy - Key
  /*6LffRtsbAAAAAIURcVT3-1jHgY7WZ_Neg2Z5XbnS - Secret*/

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (isLoggedIn) {
      if (loginAttemptUsername == "nn02") {
        _history.push(WebUrl._URL_CAMO_HOMEPAGE);
      } else {
        if (!window.ReactNativeWebView) {
          if (userData["selectedTheme"] === 0) {
            _history.push(WebUrl._URL_MAIN);
          } else if (userData["selectedTheme"] === 1) {
            _history.push(WebUrl._URL_DASHBOARD_V2);
          }
        }
      }
    } else {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "logout",
          })
        );
      }
    }
    if (ls.get("gR97lPRIn797")["PYTLczcty9uH"]) {
      document.getElementById("loginUsername").value =
        ls.get("gR97lPRIn797")["Do3SwI4cuvqR"];
      document.getElementById("loginPassword").value =
        ls.get("gR97lPRIn797")["gR1IPGBN01dS"];
    }
  }, [isLoggedIn]);

  /// <summary>
  /// Author: -
  /// </summary>
  const submitLoginForm = async (data, e) => {
    _dispatch(setBusy());
    setLoginAttemptUsername(data.username);
    if (data.isKeepedPassword) {
      ls.set("gR97lPRIn797", {
        Do3SwI4cuvqR: data.username,
        gR1IPGBN01dS: data.password,
        PYTLczcty9uH: data.isKeepedPassword,
      });
    } else {
      ls.set("gR97lPRIn797", {
        Do3SwI4cuvqR: "fake",
        gR1IPGBN01dS: "null",
        PYTLczcty9uH: false,
      });
    }
    await _dispatch(
      performLogin(data.username, data.password, data.isKeepedLogin)
    );
    let params = {
      username: data.username,
    };
    var loginFailCountJson = await ApiEngine.post(
      ApiUrl._API_GET_LOGIN_FAILED_COUNT,
      createFormBody(params)
    );
    if (loginFailCountJson[ApiKey._API_SUCCESS_KEY]) {
      let data = loginFailCountJson[ApiKey._API_DATA_KEY];
      setLoginFailCount(data);
    }
    _dispatch(setIdle());
  };

  /// <summary>
  /// Author: -
  /// </summary>
  async function submitForgotPasswordForm(data) {
    _dispatch(setBusy());
    data["phoneNumber"] = formattedPhoneNumber;
    data["language"] = i18n.language;

    var responseJson = await ApiEngine.post(
      ApiUrl._API_SEND_RESET_PASSWORD_SMS,
      createMultiPartFormBody(data)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      resetForm();
    }

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());
  }

  /// <summary>
  /// Author: -
  /// </summary>
  function resetForm() {
    forgotPasswordUnregister(["username", "phoneNumber"]);
    setFormattedPhoneNumber("");
    setIsModalVisible(false);
  }

  async function onChange(value) {
    let params = {
      username: loginAttemptUsername,
    };
    var loginJson = await ApiEngine.post(
      ApiUrl._API_MANAGE_MEMBER_LOGIN_FAIL_COUNT,
      createFormBody(params)
    );
    if (loginJson[ApiKey._API_SUCCESS_KEY]) {
      setLoginFailCount(0);
      _dispatch(
        showResponseMessage(
          loginJson[ApiKey._API_SUCCESS_KEY],
          loginJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }

  const onRecaptchaSubmit = () => {
    const recaptchaValue = recaptcharef.current.getValue();
  };

  /// <summary>
  /// Author: Wong
  /// </summary>
  const onChangeLanguage = async (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);

    _dispatch(saveLanguageId(selectedLanguage, true));
  };

  return (
    <div className="login">
      <Select
        className="lang-selector"
        menuPlacement="auto"
        menuPosition="fixed"
        options={LanguageOption.map((x) => {
          return { label: x["prefix"], value: x["value"] };
        })}
        isSearchable={false}
        styles={LanguageSelectStyle}
        placeholder={
          LanguageOption.filter(
            (option) =>
              option.value == localStorage.getItem(SessionKey._LANGUAGE)
          )[0] !== undefined
            ? LanguageOption.filter(
              (option) =>
                option.value == localStorage.getItem(SessionKey._LANGUAGE)
            )[0].prefix
            : "EN"
        }
        value={
          LanguageOption.filter(
            (option) =>
              option.value == localStorage.getItem(SessionKey._LANGUAGE)
          )[0] !== undefined
            ? LanguageOption.filter(
              (option) =>
                option.value == localStorage.getItem(SessionKey._LANGUAGE)
            )[0].prefix
            : Language._ENGLISH
        }
        onChange={(e) => {
          onChangeLanguage(e.value);
        }}
      />
      {window.ReactNativeWebView && (
        <button
          className="btn login-btn qrscan-container"
          onClick={() => {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ action: "scan_referral_code" })
            );
          }}
        >
          <img
            src={require("../assets/img/icon/scan-qr-icon.png")}
            style={{ width: "20px" }}
          />
        </button>
      )}
      <div
        className="container"
        style={{
          height: "450px",
          margin: "auto",
          inset: "0",
          position: "absolute",
          top: "-50px",
        }}
      >
        <div className="brand-header">
          <img
            src={require("../assets/img/icon/i8-logo.png")}
            style={{ width: "55px" }}
          />
        </div>
        <form
          className="margin-bottom-0"
          onSubmit={loginHandleSubmit(submitLoginForm)}
        >
          <Input
            id="loginUsername"
            name="username"
            ref={loginRegister({ required: true })}
            autoFocus
            errors={loginErrors}
            placeholder={t("ENTER_USERNAME")}
          />
          <Input
            id="loginPassword"
            name="password"
            ref={loginRegister({ required: true })}
            type="password"
            errors={loginErrors}
            placeholder={t("ENTER_PASSWORD")}
          />
          <Checkbox
            id="keep-login-checkbox"
            label={t("KEEP_LOGGED_IN")}
            labelClassName="form-checkbox-label"
            name="isKeepedLogin"
            ref={loginRegister()}
            type="checkbox"
            errors={loginErrors}
          />
          <Checkbox
            id="keep-password-checkbox"
            label={t("KEEP_PASSWORD")}
            labelClassName="form-checkbox-label"
            name="isKeepedPassword"
            defaultChecked={ls.get("gR97lPRIn797")["PYTLczcty9uH"]}
            ref={loginRegister()}
            type="checkbox"
            errors={loginErrors}
          />

          <div
            className="d-flex justify-content-between mt-3"
            style={{ marginBottom: "50px" }}
          >
            <button
              type="button"
              className="btn btn-link p-0"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              {t("FORGOT_PASSWORD")} ?
            </button>
            <button className="btn btn-brand-gradient login-btn">
              {t("LOGIN")}
            </button>
          </div>
        </form>

        {/*loginFailCount < 0 || loginFailCount >= 3 &&
                  <form style={{textAlign:"-webkit-center"}} onSubmit={recaptchaSubmit(onRecaptchaSubmit)} >
                      <ReCAPTCHA
                          ref={recaptcharef}
                          size="compact"
                          sitekey={TEST_SITE_KEY}
                          onChange={onChange}
                      />
                  </form>
              */}
      </div>
      <div className="login-bottom" style={{ marginTop: "50px" }}>
        <div className="login-bottom-left">
          {!window.ReactNativeWebView ? <>
            {t("RECOMMENDED_BROWSER")} <br />
            <img
              className="login-browser-icon"
              src={require("../assets/img/icon/chrome-logo.png")}
            />
            <img
              className="login-browser-icon"
              src={require("../assets/img/icon/firefox-logo.png")}
            />
          </> :
            <>
              {t("FOLLOW_US")} <br />
              <img
                className="login-browser-icon social-media"
                src={require("../assets/img/icon/facebook.png")}
                onClick={() => window.open("https://www.facebook.com/i8OfficialTeam")}
              />
              <img
                className="login-browser-icon social-media"
                src={require("../assets/img/icon/youtube.png")}
                onClick={() => window.open("https://www.youtube.com/channel/UCxrKFaJx8jENt4hjrnKtYiQ")}
              />
              {/*<img
                className="login-browser-icon social-media"
                src={require("../assets/img/icon/twitter.png")}
                onClick={() => window.open("https://www.facebook.com/I8live-110864344805611")}
              />*/}
              <img
                className="login-browser-icon social-media"
                src={require("../assets/img/icon/Instagram.png")}
                onClick={() => window.open("https://www.instagram.com/i8.live/?hl=en")}
              />
            </>
            }
        </div>
        <div className="login-bottom-right">
          Version. <br />
          <div
            style={{ marginTop: "5px" }}
            onClick={() => window.location.reload()}
          >
            <i className="fas fa-redo-alt" style={{ marginRight: "10px" }} />
            {/*"1.2.345"*/ process.env.REACT_APP_VERSION}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalVisible}
        contentClassName="forget-password modal-brand modal-bottom"
        toggle={() => {
          resetForm();
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            resetForm();
          }}
        >
          {t("FORGOT_PASSWORD")}
        </ModalHeader>
        <form onSubmit={forgotPasswordHandleSubmit(submitForgotPasswordForm)}>
          <ModalBody>
            <Input
              name="username"
              label={t("USERNAME")}
              autoComplete={"off"}
              ref={forgotPasswordRegister({ required: "REQUIRED_USERNAME" })}
              errors={forgotPasswordErrors}
              placeholder={t("ENTER_USERNAME")}
            />
            <PhoneNumber
              register={forgotPasswordRegister}
              name={"phoneNumber"}
              value={forgotPasswordWatch("phoneNumber")}
              errors={forgotPasswordErrors}
              onChange={(value, formattedValue) => {
                forgotPasswordSetValue("phoneNumber", value);
                setFormattedPhoneNumber(formattedValue);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <div
              className="d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <button
                type="submit"
                className="btn btn-brand-gradient"
                style={{ width: "45%" }}
              >
                {" "}
                {t("RESET")}{" "}
              </button>
              <button
                type="button"
                className="btn btn-red-gradient"
                style={{ width: "45%" }}
                onClick={() => {
                  resetForm();
                }}
              >
                {t("CANCEL")}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default withRouter(Login);
